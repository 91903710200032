<template>
    <div>
        <a-form-model-item
        label="Please select at lease one 请至少选择一项"
        :prop="variable">
            <a-checkbox-group
                class="sof_checkbox_container"
                :value="SelectList"
                :options="selectOptions"
                @change="onChange"
                :disabled='disabled'
            >
                <span slot="label" slot-scope="{ value }">{{ value }}</span>
            </a-checkbox-group>
        </a-form-model-item>
    </div>
</template>

<script>
export default {
  props: {
    variable: {
      type: String,
      default: ''
    },
    SelectList: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectOptions: [
        {
          id: 1,
          label: 'Business Income 商业收入',
          value: 'business_income'
        },
        {
          id: 2,
          label: 'Employment (Salary, Wages, Bonuses, Business Activity) 工资收入',
          value: 'salary'
        },
        {
          id: 3,
          label: 'Gifts 礼物赠送',
          value: 'gifts'
        },
        {
          id: 4,
          label: 'Inheritance 财产继承',
          value: 'inheritance'
        },
        {
          id: 5,
          label: 'Loan Drawdown 贷款',
          value: 'loan_drawdown'
        },
        {
          id: 6,
          label: 'Rental Income 租金收入',
          value: 'rental'
        },
        {
          id: 7,
          label: 'Sale of Property 物业出售',
          value: 'property_sales'
        },
        {
          id: 8,
          label: 'Sales of Shares 股份出售',
          value: 'shares_sales'
        },
        {
          id: 10,
          label: 'Other 其他',
          value: 'other'
        }
      ]
    }
  },
  methods: {
    onChange (checkedList) {
      this.$emit('onChange', checkedList, this.variable)
    }
  }
}
</script>

<style scoped>
.sof_checkbox_container {
    display: flex;
    flex-direction: column;
}

.sof_checkbox_container >>> .ant-checkbox-wrapper {
    font-size: 15px;
    font-weight: 300;
    margin: 0.5rem;
}
.sof_checkbox_container >>> .ant-checkbox-inner {
    width: 30px;
    height: 30px;
}
.sof_checkbox_container >>> .ant-checkbox-inner::after {
    width: 9px;
    height: 18px;
}
</style>
