<template>
<div class="step_container">
  <h1 class="step_title">TRANSACTION DETAILS 交易信息</h1>
  <a-form-model
    layout="horizontal"
    :rules="rules"
    :model="secondForm"
    :label-col="labelCol"
    ref="entity_step_two"
    :wrapper-col="wrapperCol"
  >
    <div class="step_question_container">
      <div class="step_question_title">SOURCE OF FUNDS 资金来源</div>
      <div class="step_sub_title">
        Please provide your source of Funds/ Wealth
        请大致选择您的收入来源（这部分会在您交易外汇的时候验证）
      </div>
      <BaseSOF
        :disabled="disabled"
        variable="entity_sof_list"
        :SelectList="entity_sof_list"
        @onChange="handleInput"
      ></BaseSOF>
      <!-- <a-form-model-item
        v-show="showPofOther"
        prop="entity_pof_other_text"
        label="其他换汇目的"
      >
        <a-textarea
          :disabled="disabled"
          :value="entity_pof_other_text"
          @change="
            ({ target: { value } }) => {
              handleInput(value, 'entity_pof_other_text')
            }
          "
          :auto-size="{ minRows: 3, maxRows: 3 }"
        />
        <div class="textarea_desc">
          I.e.Repatriate overseas salary back to Australia/New Zealand;
          purchasing property in Australia/New Zealand; paying for overseas
          purchases,mainly cosmetics; paying tuition and living cost in
          Australia/New Zealand. 将海外工资汇回澳大利亚/新西兰;
          在澳大利亚/新西兰购买房产; 支付海外购物，主要是化妆品;
          在澳大利亚/新西兰支付学费和生活费。
        </div>
      </a-form-model-item> -->
    </div>
    <div class="step_question_container">
      <div class="step_question_title">Account Behaviour 账号相关</div>
      <a-form-model-item
        prop="entity_purpose_of_transaction_text"
        label="Purpose of Transaction 换汇目的"
      >
        <a-select
          :disabled="disabled"
          @change="
            (e) => {
              handleInput(e, 'entity_purpose_of_transaction_text')
            }
          "
          :value="entity_purpose_of_transaction_text"
        >
          <a-select-option
            v-for="item in pof_list"
            :key="item.id"
            :value="item.desc"
            >{{ item.desc }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        v-show="entity_purpose_of_transaction_text === 'Other'"
        prop="entity_purpose_of_transaction_other_text"
        label="其他换汇目的"
      >
        <a-textarea
          :disabled="disabled"
          placeholder=""
          :value="entity_purpose_of_transaction_other_text"
          @change="
            ({ target: { value } }) => {
              handleInput(value, 'entity_purpose_of_transaction_other_text')
            }
          "
          :auto-size="{ minRows: 3, maxRows: 3 }"
        />
        <div class="textarea_desc">
          I.e.Repatriate overseas salary back to Australia/New Zealand;
          purchasing property in Australia/New Zealand; paying for overseas
          purchases,mainly cosmetics; paying tuition and living cost in
          Australia/New Zealand. 将海外工资汇回澳大利亚/新西兰;
          在澳大利亚/新西兰购买房产; 支付海外购物，主要是化妆品;
          在澳大利亚/新西兰支付学费和生活费。
        </div>
      </a-form-model-item>
      <a-form-model-item
        prop="entity_transaction_country_list"
        label="List of countries normally dealing/trading with 请列出您产生交易/贸易的国家或地区"
      >
        <a-textarea
          :disabled="disabled"
          placeholder=""
          :value="entity_transaction_country_list"
          @change="
            ({ target: { value } }) => {
              handleInput(value, 'entity_transaction_country_list')
            }
          "
          :auto-size="{ minRows: 3, maxRows: 3 }"
        />
      </a-form-model-item>
       <a-form-model-item
        prop="entity_total_volumn_of_trasaction_per_year"
        label="Approx. number trade volume (in NZD) 大约每年交易金额是多少（纽币）"
      >
        <a-select
            :disabled="disabled"
            @change="
            (e) => {
              handleInput(e, 'entity_total_volumn_of_trasaction_per_year')
            }
          "
            :value="entity_total_volumn_of_trasaction_per_year"
          >
            <a-select-option value="$1 - $100,000"> $1 - $100,000 </a-select-option>
            <a-select-option value="$100,001 - $200,000"> $100,001 - $200,000 </a-select-option>
            <a-select-option value="$200,001 - $500,000"> $200,001 - $500,000 </a-select-option>
            <a-select-option value="$500,001 - $1,000,000"> $500,001 - $1,000,000 </a-select-option>
            <a-select-option value="> $1,000,000"> > $1,000,000 </a-select-option>
          </a-select>
       </a-form-model-item>

       <p class="claim_text mt-20px">
          In accordance with AML/CFT Act regulations, we are required to collect and verify proof of funds for participants before the transaction take place.  Please provide valid and up-to-date documentation that demonstrates the source and availability of the funds. Rest assured that all provided information will be treated with strict confidentiality and used solely for AML compliance purposes. Thank you for your understanding and cooperation in helping us maintain a secure and compliant survey process.<br>
          根据《反洗钱/反恐怖融资法案》的规定，我们需要在交易进行之前收集和验证参与者的资金来源和可用性的证明文件。请提供有效和最新的文件，以展示资金来源和可用性。请放心，所有提供的信息将被严格保密，仅用于反洗钱合规目的。感谢您的理解和合作，帮助我们保持安全合规的调查流程。
        </p>
        <a-form-model-item prop="entity_aml_announcement_check">
          <a-checkbox
            name="entity_aml_announcement_check"
            :disabled="disabled"
            class="antd_checkbox"
            :checked="entity_aml_announcement_check"
            @change="handleCheckChange">
              Read & Consent 已读确认
          </a-checkbox>
        </a-form-model-item>
    </div>
    <!-- <div class="step_question_container">
      <div class="step_question_title">
        Transaction and Payment 交易和付款
      </div>
      <a-form-model-item
        prop="entity_num_of_anual_transactions"
        label="Approx. number of annual transactions 大约每年交易次数是多少"
      >
        <a-input
          :disabled="disabled"
          :value="entity_num_of_anual_transactions"
          @change="
            ({ target: { value } }) => {
              handleInput(value, 'entity_num_of_anual_transactions')
            }
          "
          placeholder=""
        >
        </a-input>
      </a-form-model-item>
      <a-form-model-item
        prop="entity_frequency_of_transaction"
        label="Approx. number & frequency of transactions 大约交易频率是多少"
      >
        <a-input
          :disabled="disabled"
          :value="entity_frequency_of_transaction"
          @change="
            ({ target: { value } }) => {
              handleInput(value, 'entity_frequency_of_transaction')
            }
          "
          placeholder=""
        >
        </a-input>
        <div class="textarea_desc">
          I.e. 5 weekly / 3 monthly / 2 quarterly
        </div>
      </a-form-model-item>
      <a-form-model-item
        prop="entity_total_volumn_of_trasaction_per_year"
        label="Approx. number tade volume (in NZD) 大约每年交易金额是多少（纽币）"
      >
        <a-input
          :disabled="disabled"
          @change="
            ({ target: { value } }) => {
              handleInput(value, 'entity_total_volumn_of_trasaction_per_year')
            }
          "
          :value="entity_total_volumn_of_trasaction_per_year"
        />
      </a-form-model-item>
      <a-form-model-item
        prop="entity_average_volume_of_per_transaction"
        label="Approx. average transaction volume (in NZD) 大约平均每笔交易金额是多少（纽币）"
      >
        <a-input
          :disabled="disabled"
          @change="
            ({ target: { value } }) => {
              handleInput(value, 'entity_average_volume_of_per_transaction')
            }
          "
          :value="entity_average_volume_of_per_transaction"
        />
      </a-form-model-item>
    </div> -->
  </a-form-model>
</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import BaseSOF from '@/components/BaseSOF.vue'
import rules from '@/assets/rules/entityTwoRules.js'
import cnFilter from '@/utils/chineseFilter.js'

export default {
  components: {
    BaseSOF
  },
  created () {
    this.rules = rules
  },
  beforeRouteLeave (to, from, next) {
    if (this.disabled) {
      next()
    } else {
      this.$refs.entity_step_two.validate(valid => {
        if (valid) {
          next()
        } else {
          next()
        }
      })
    }
  },

  data () {
    return {
      pof_list: [
        {
          id: 1,
          desc: 'Capital injection'
        },
        {
          id: 2,
          desc: 'Commission distribution'
        },
        {
          id: 3,
          desc: 'Educational fees payment'
        },
        {
          id: 4,
          desc: 'Exchange rate hedge'
        },
        {
          id: 5,
          desc: 'Gift(specify - reason and from who)'
        },
        {
          id: 6,
          desc: 'Living expenses'
        },
        {
          id: 7,
          desc: 'Loan'
        },
        {
          id: 8,
          desc: 'Mortgage/loan repayment'
        },
        {
          id: 9,
          desc: 'Overseas travel'
        },
        {
          id: 10,
          desc: 'Payment for freight services'
        },
        {
          id: 11,
          desc: 'Payment for goods/service(specify types of goods/service)'
        },
        {
          id: 12,
          desc: 'Personal investment(specify types of investment)'
        },
        {
          id: 13,
          desc: 'Property maintenance'
        },
        {
          id: 14,
          desc: 'Property purchase'
        },
        {
          id: 15,
          desc: 'Property sale'
        },
        {
          id: 16,
          desc: 'Repatriate overseas revenue back to NZ'
        },
        {
          id: 17,
          desc: 'Business Travel Expense'
        },
        {
          id: 18,
          desc: 'Inter-company Loan, Transfer'
        },
        {
          id: 19,
          desc: 'Reimbursement'
        },
        {
          id: 20,
          desc: 'Salary payment sent home'
        },
        {
          id: 21,
          desc: 'Salary received in foreign currency'
        },
        {
          id: 22,
          desc: 'Transfer of savings'
        },
        {
          id: 23,
          desc: 'Vehicle purchase/maintenance'
        },
        {
          id: 24,
          desc: 'Other'
        }
      ],
      sof_default_list: [
        {
        }
      ],
      labelCol: { span: 24 },
      wrapperCol: { span: 24 }
    }
  },
  computed: {
    secondForm () {
      return this.$store.state.entityStepTwo
    },
    showPofOther () {
      if (this.entity_sof_list.includes('other')) {
        return true
      } else {
        return false
      }
    },
    ...mapGetters({
      disabled: 'login/getComplainceActive'
    }),
    ...mapState('entityStepTwo', ['entity_purpose_of_transaction_text', 'entity_sof_list', 'entity_pof_other_text', 'entity_purpose_of_transaction_other_text', 'entity_transaction_country_list', 'entity_num_of_anual_transactions', 'entity_frequency_of_transaction', 'entity_total_volumn_of_trasaction_per_year', 'entity_average_volume_of_per_transaction', 'entity_aml_announcement_check'])
  },
  methods: {
    ...mapActions('entityStepTwo', ['handleChangeAction', 'ChangeFormAction']),
    handleInput (value, variable) {
      this.handleChangeAction({ variable, value: cnFilter(value) })
    },
    handleCheckChange (e) {
      const { checked, name } = e.target
      this.ChangeFormAction({ variable: name, value: checked })
    }
  }
}
</script>

<style scoped>
.antd_checkbox >>> .ant-checkbox-wrapper {
  font-size: 15px;
}
.antd_checkbox >>> .ant-checkbox-inner{
  width: 30px;
  height: 30px;
}
.antd_checkbox >>> .ant-checkbox-inner::after{
  width: 9px;
  height: 18px;
}
</style>
