var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step_container"},[_c('h1',{staticClass:"step_title"},[_vm._v("TRANSACTION DETAILS 交易信息")]),_c('a-form-model',{ref:"entity_step_two",attrs:{"layout":"horizontal","rules":_vm.rules,"model":_vm.secondForm,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('div',{staticClass:"step_question_container"},[_c('div',{staticClass:"step_question_title"},[_vm._v("SOURCE OF FUNDS 资金来源")]),_c('div',{staticClass:"step_sub_title"},[_vm._v(" Please provide your source of Funds/ Wealth 请大致选择您的收入来源（这部分会在您交易外汇的时候验证） ")]),_c('BaseSOF',{attrs:{"disabled":_vm.disabled,"variable":"entity_sof_list","SelectList":_vm.entity_sof_list},on:{"onChange":_vm.handleInput}})],1),_c('div',{staticClass:"step_question_container"},[_c('div',{staticClass:"step_question_title"},[_vm._v("Account Behaviour 账号相关")]),_c('a-form-model-item',{attrs:{"prop":"entity_purpose_of_transaction_text","label":"Purpose of Transaction 换汇目的"}},[_c('a-select',{attrs:{"disabled":_vm.disabled,"value":_vm.entity_purpose_of_transaction_text},on:{"change":function (e) {
              _vm.handleInput(e, 'entity_purpose_of_transaction_text')
            }}},_vm._l((_vm.pof_list),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.desc}},[_vm._v(_vm._s(item.desc))])}),1)],1),_c('a-form-model-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.entity_purpose_of_transaction_text === 'Other'),expression:"entity_purpose_of_transaction_text === 'Other'"}],attrs:{"prop":"entity_purpose_of_transaction_other_text","label":"其他换汇目的"}},[_c('a-textarea',{attrs:{"disabled":_vm.disabled,"placeholder":"","value":_vm.entity_purpose_of_transaction_other_text,"auto-size":{ minRows: 3, maxRows: 3 }},on:{"change":function (ref) {
              var value = ref.target.value;

              _vm.handleInput(value, 'entity_purpose_of_transaction_other_text')
            }}}),_c('div',{staticClass:"textarea_desc"},[_vm._v(" I.e.Repatriate overseas salary back to Australia/New Zealand; purchasing property in Australia/New Zealand; paying for overseas purchases,mainly cosmetics; paying tuition and living cost in Australia/New Zealand. 将海外工资汇回澳大利亚/新西兰; 在澳大利亚/新西兰购买房产; 支付海外购物，主要是化妆品; 在澳大利亚/新西兰支付学费和生活费。 ")])],1),_c('a-form-model-item',{attrs:{"prop":"entity_transaction_country_list","label":"List of countries normally dealing/trading with 请列出您产生交易/贸易的国家或地区"}},[_c('a-textarea',{attrs:{"disabled":_vm.disabled,"placeholder":"","value":_vm.entity_transaction_country_list,"auto-size":{ minRows: 3, maxRows: 3 }},on:{"change":function (ref) {
              var value = ref.target.value;

              _vm.handleInput(value, 'entity_transaction_country_list')
            }}})],1),_c('a-form-model-item',{attrs:{"prop":"entity_total_volumn_of_trasaction_per_year","label":"Approx. number trade volume (in NZD) 大约每年交易金额是多少（纽币）"}},[_c('a-select',{attrs:{"disabled":_vm.disabled,"value":_vm.entity_total_volumn_of_trasaction_per_year},on:{"change":function (e) {
              _vm.handleInput(e, 'entity_total_volumn_of_trasaction_per_year')
            }}},[_c('a-select-option',{attrs:{"value":"$1 - $100,000"}},[_vm._v(" $1 - $100,000 ")]),_c('a-select-option',{attrs:{"value":"$100,001 - $200,000"}},[_vm._v(" $100,001 - $200,000 ")]),_c('a-select-option',{attrs:{"value":"$200,001 - $500,000"}},[_vm._v(" $200,001 - $500,000 ")]),_c('a-select-option',{attrs:{"value":"$500,001 - $1,000,000"}},[_vm._v(" $500,001 - $1,000,000 ")]),_c('a-select-option',{attrs:{"value":"> $1,000,000"}},[_vm._v(" > $1,000,000 ")])],1)],1),_c('p',{staticClass:"claim_text mt-20px"},[_vm._v(" In accordance with AML/CFT Act regulations, we are required to collect and verify proof of funds for participants before the transaction take place. Please provide valid and up-to-date documentation that demonstrates the source and availability of the funds. Rest assured that all provided information will be treated with strict confidentiality and used solely for AML compliance purposes. Thank you for your understanding and cooperation in helping us maintain a secure and compliant survey process."),_c('br'),_vm._v(" 根据《反洗钱/反恐怖融资法案》的规定，我们需要在交易进行之前收集和验证参与者的资金来源和可用性的证明文件。请提供有效和最新的文件，以展示资金来源和可用性。请放心，所有提供的信息将被严格保密，仅用于反洗钱合规目的。感谢您的理解和合作，帮助我们保持安全合规的调查流程。 ")]),_c('a-form-model-item',{attrs:{"prop":"entity_aml_announcement_check"}},[_c('a-checkbox',{staticClass:"antd_checkbox",attrs:{"name":"entity_aml_announcement_check","disabled":_vm.disabled,"checked":_vm.entity_aml_announcement_check},on:{"change":_vm.handleCheckChange}},[_vm._v(" Read & Consent 已读确认 ")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }